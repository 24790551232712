import React from 'react';
import { IContentDetailsParent } from './types';
import RetiredContentDetails from './RetiredDocumentDetailsContent';
import DocumentDetailsContent from './DocumentDetailsContent';
import { DocumentState } from '@common/features/submission/types';
import ReadonlyDocumentDetailsContent from './ReadonlyDocumentDetailsContent';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { useDownloadAndOpenPublishedFile } from '@hooks/useDownloadAndOpenPublishedFile';
import { DocumentStatus } from '@common/types';
import { useFetchPublicProductGroupState } from '@features/productGroup/hooks/useFetchPublicProductGroupState';
import { useGetOriginalFileExtensionType } from '@hooks/useGetOriginalFileExtensionType';
import { hasOriginalFilePublished, htmlFileIsAvailable } from '@features/document/helpers';

export const DocumentDetailsParentContent = ({
  submission,
  submissionId,
  documentId,
  contentType,
  isFormExtended = true,
  documentDetailsReadonlySections,
  isEditable = false,
  isCustomerApprovalRequired,
  isAdmin,
  customerUrl,
  onCancel,
  onSuccess,
}: IContentDetailsParent) => {
  const { downloadAndOpen } = useDownloadAndOpenFile();
  const { downloadAndOpen: downloadAndOpenPublishedDocument } = useDownloadAndOpenPublishedFile();
  const { getExtensionByDocumentType } = useGetOriginalFileExtensionType();

  const document = submission?.documents.find((d) => d.documentId === Number(documentId) && d.type === contentType);

  const productGroupName = submission?.title;
  const contentTitle = document?.documentTitle ?? '';
  const originalFileName = document?.originalFileName ?? '';
  const version = document?.version ?? 1;

  const { publicProductGroupState } = useFetchPublicProductGroupState(submission?.productGroupId?.toString());

  const handleFileDownload = () => downloadAndOpen({ submissionId, documentId });
  const handleDocumentDownload = () =>
    downloadAndOpenPublishedDocument({
      documentId,
      version: version.toString(),
      documentType: document?.type,
      extensionType: getExtensionByDocumentType(document?.type),
    });

  const documentWasPublished = document?.status === DocumentStatus.Published || document?.status === DocumentStatus.Retired;
  const documentHasOriginalFilePublished = hasOriginalFilePublished(document);
  const getDocument = documentWasPublished
    ? documentHasOriginalFilePublished
      ? handleDocumentDownload
      : undefined
    : handleFileDownload;
  const displayHtmlView =
    !!document && !!publicProductGroupState && htmlFileIsAvailable(document, publicProductGroupState.documents);

  if (isEditable && document?.state !== DocumentState.Retired) {
    return (
      <DocumentDetailsContent
        onCancel={onCancel}
        isFormExtended={isFormExtended}
        isCustomerApprovalRequired={isCustomerApprovalRequired}
        documentReadonlySections={documentDetailsReadonlySections}
        productGroupName={productGroupName}
        submissionId={parseInt(submissionId)}
        documentId={documentId}
        url={document!.link || ''}
        customerUrl={customerUrl}
        contentType={contentType}
        contentTitle={contentTitle}
        originalFileName={originalFileName}
        version={version}
        onSuccess={onSuccess}
        onFileDownload={getDocument}
        showHistoryFiltering={false}
        documents={submission.documents}
      />
    );
  }

  if (document?.state === DocumentState.Retired) {
    return (
      <RetiredContentDetails
        document={document}
        submissionId={submissionId}
        documentId={documentId}
        contentType={contentType}
        contentTitle={contentTitle}
        originalFileName={originalFileName}
        onCancel={onCancel}
        onSuccess={onSuccess}
        onFileDownload={getDocument}
        displayHtmlViewTab={!!displayHtmlView}
        showHistoryFiltering={false}
      />
    );
  }

  return (
    <ReadonlyDocumentDetailsContent
      contentType={contentType}
      title={productGroupName}
      isCustomerApprovalRequired={isCustomerApprovalRequired}
      document={document!}
      onCancel={onCancel}
      onFileDownload={getDocument}
      displayHtmlViewTab={!!displayHtmlView}
      submissionId={submissionId}
      showHistoryFiltering={false}
      customerUrl={customerUrl}
      isAdmin={isAdmin}
    />
  );
};
