import { useForm } from 'react-hook-form';
import { ContentDetailsFormField, IContentDetailsForm } from '../../../pages/ContentDetails/types';
import { IDocumentDto } from '@features/submission/types';
import { RegulatorApprovalLabels, RegulatorApprovalValues } from '../../RegulatorApproval/types';
import { validateDocumentTitleUniqueness } from '@common/features/document/helpers';
import { alreadyExistingDocumentTitleMessage } from '@common/components/DocumentTitle/constants';

export const usePrepareContentDetailsForm = (
  isCustomerApprovalRequired: boolean | null,
  customerUrl?: string,
  documents?: IDocumentDto[],
  documentId?: number,
) => {
  const document = documents?.find(x => x.documentId === documentId);
  const isRegulatorApproved = !!document?.regulatorApprovalDate;

  const methods = useForm<IContentDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      [ContentDetailsFormField.approvedByRegulator]: isRegulatorApproved
        ? {
            label: document?.approvedByRegulator as RegulatorApprovalLabels,
            value: document?.approvedByRegulator as RegulatorApprovalValues,
          }
        : { label: RegulatorApprovalLabels.MHRA, value: RegulatorApprovalValues.MHRA },
      [ContentDetailsFormField.regulatorApprovalDate]: isRegulatorApproved ? document?.regulatorApprovalDate! : undefined,
      [ContentDetailsFormField.authorisedDate]: isRegulatorApproved ? document?.authorisedDate : undefined,
      [ContentDetailsFormField.reasonsForSubmission]: document?.reasonsForSubmission ?? [],
      [ContentDetailsFormField.isRegulatorApproved]: isRegulatorApproved,
      [ContentDetailsFormField.details]: document?.details,
      [ContentDetailsFormField.documentTitle]: document?.documentTitle ?? '',
      [ContentDetailsFormField.hasDigitalVersion]: document?.hasDigitalVersion,
      [ContentDetailsFormField.hasAlternativeTextFile]: false,
      [ContentDetailsFormField.alternativeTextFile]: undefined,
      [ContentDetailsFormField.alternativeTextFileName]: undefined,
      [ContentDetailsFormField.isCustomerApprovalRequired]: isCustomerApprovalRequired,
      [ContentDetailsFormField.isVisibleToHCP]: document?.isVisibleToHCP,
      [ContentDetailsFormField.contentDescription]: document?.contentDescription ?? undefined,
      [ContentDetailsFormField.webLink]: document?.link ?? '',
      [ContentDetailsFormField.htmlContent]: document?.htmlContent ?? '',
      [ContentDetailsFormField.customerUrl]: customerUrl,
    },
  });

  const { register, getValues } = methods;

  register(ContentDetailsFormField.documentTitle, {
    validate: () => { 
      const documentTitle = getValues(ContentDetailsFormField.documentTitle);
      const contentType = document?.type;
      const isDocumentTitleUnique = validateDocumentTitleUniqueness(documents!, documentTitle!, contentType!, document?.documentId!.toString()!);
      
      return isDocumentTitleUnique ? alreadyExistingDocumentTitleMessage(contentType!) : undefined}
  });

  return { methods, ...methods };
};
