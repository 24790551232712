import { AllergyAndPregnancyRiskInfo } from '@common/components/AllergyAndPregnancyRiskInfo';
import { StyledLink } from '@common/components/Link/styles';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { TitleWeblinkCell } from '@common/features/document/components/TitleWeblinkCell';
import { getDocumentAttributesForSummary } from '@common/features/document/helpers';
import { TaskType } from '@common/features/productGroup/styles';
import { DocumentState, IDocumentWithTask, IProductDto } from '@common/features/submission/types';
import {
  TaskTypeToStringMap,
  boolToYesNo,
  getDaysSinceWithDistanceFormat,
  getProductIcon,
  getProductTypeLabel,
  getTaskIcon,
  isStringNullOrEmpty,
  formatDate
} from '@common/helpers';
import { BlackTriangle, MultiRowText, StyledSVG } from '@common/styles';
import { DocumentType, Id, SubmissionTaskType } from '@common/types';
import { getLegalCategories } from '@components/LegalCategories/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import DOMPurify from 'dompurify';
import React from 'react';
import { IProductGroupTable, ISubmissionDetailsTable } from './types';

export const prepareSubmissionDetailsColumns = () => {
  const columnHelper = createColumnHelper<ISubmissionDetailsTable>();

  return [
    columnHelper.accessor('company', {
      header: 'Company',
    }),
    columnHelper.accessor('submitter', {
      header: 'Submitter',
    }),
    columnHelper.accessor('submissionDate', {
      header: 'Submission date',
    }),
  ];
};

export const prepareProductGroupColumns = () => {
  const columnHelper = createColumnHelper<IProductGroupTable>();

  return [
    columnHelper.accessor('productGroup', {
      header: 'Product group',
    }),
    columnHelper.accessor('maHolder', {
      header: 'MA Holder',
      cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor('companyName', {
      header: 'Company name',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('productType', {
      header: 'Product Type',
      cell: ({ getValue }) => getProductTypeLabel(getValue()),
    }),
  ];
};

interface PrepareContentColumns {
  onFileOpen: (taskId: Id) => void;
  onViewContent: (text: string, title: string) => void;
  onUrlClick: (e: any, url: string) => void;
}

export const prepareContentColumns = ({ onFileOpen, onViewContent }: PrepareContentColumns) => {
  const columnHelper = createColumnHelper<IDocumentWithTask>();

  return [
    columnHelper.accessor('taskType', {
      header: 'Type',
      cell: ({ getValue, row }) => {
        const taskType = getValue();
        const documentState = row.original.state;
        const documentType = row.original.type;
        return (
          <TaskType>
            {getContentChangeDisplayValue(taskType, documentState, documentType)}
            <StyledSVG height={25} src={getTaskIcon(taskType!)} />
          </TaskType>
        );
      },
    }),
    columnHelper.accessor('submissionTaskId', {
      header: 'Task ID',
    }),
    columnHelper.accessor('documentTitle', {
      header: 'Content title',
      cell: ({ row, getValue }) => (
        <TitleWeblinkCell
          id={row.original.submissionTaskId!}
          text={getValue()}
          htmlContent={row.original.htmlContent}
          link={isStringNullOrEmpty(row.original.link) ? row.original.customerLink : row.original.link}
          onViewContent={onViewContent}
          onFileOpen={onFileOpen}
        />
      ),
    }),
    columnHelper.accessor('reasonsForSubmission', {
      header: 'Reason for submission',
      cell: ({ getValue, row }) => {
        const documentState = row.original.state;
        const shouldRenderRetireReasons = documentState === DocumentState.Retired;

        const submissionReasons =
          getValue()?.length > 0 ? (
            <MultiRowText>
              {getValue()
                ?.map((r) => r.text)
                .join('\n')}
            </MultiRowText>
          ) : (
            '-'
          );

        return shouldRenderRetireReasons ? row.original.reasonForRetirement : submissionReasons;
      },
    }),
    columnHelper.accessor('version', {
      header: 'Version',
    }),
    columnHelper.accessor('regulatorApprovalDate', {
      header: 'Regulator approval date',
      cell: ({ row, getValue }) => {
        const value = getValue();

        return getDocumentAttributesForSummary(row.original.type).showRegulatorApprovalDate && value
          ? formatDate(value)
          : '-';
      },
    }),
    columnHelper.display({
      header: 'Days since approval',
      cell: ({ row }) => {
        const value = !!row.original.regulatorApprovalDate ? new Date(row.original.regulatorApprovalDate!) : undefined;
        return getDocumentAttributesForSummary(row.original.type).showDaysSinceApproval
          ? getDaysSinceWithDistanceFormat(value)
          : '-';
      },
    }),
    columnHelper.display({
      header: 'HCP only',
      cell: ({ row }) => {
        const isVisibleToHCP = boolToYesNo(row.original.isVisibleToHCP ?? false);
        return getDocumentAttributesForSummary(row.original.type).showHcpOnly ? isVisibleToHCP : '-';
      },
    }),
    columnHelper.accessor('approvedByRegulator', {
      header: 'Regulator',
      cell: ({ getValue }) => {
        return getValue() ?? '-';
      },
    }),
    columnHelper.accessor('linkedProductIds', {
      header: 'Associated products',
      cell: ({ getValue }) => getValue()?.length ?? '-',
    }),
    columnHelper.accessor('documentId', {
      header: 'Content ID',
    }),
    columnHelper.accessor('details', {
      header: 'Submission notes',
      cell: ({ row, getValue }) =>
        getValue() !== '' && getDocumentAttributesForSummary(row.original.type).showSubmissionNotes ? getValue() : '-',
    }),
    columnHelper.accessor('contentDescription', {
      header: 'Content Description',
      cell: ({ row, getValue }) => {
        return getDocumentAttributesForSummary(row.original.type).showContentDescription ? (
          <StyledLink onClick={(e) => onViewContent(DOMPurify.sanitize(getValue() ?? ''), 'Content Description')} to="#">
            {'View'}
          </StyledLink>
        ) : (
          '-'
        );
      },
    }),
    columnHelper.accessor('sla', {
      header: 'SLA',
      cell: ({ getValue }) => (getValue() ? `${getValue()} WD` : '-'),
    }),
  ];
};

export const prepareProductsColumns = () => {
  const columnHelper = createColumnHelper<IProductDto>();

  return [
    columnHelper.accessor('name', {
      header: 'Product Name',
      cell: ({ getValue, row }) => (
        <TaskType>
          {getValue()}
          <StyledSVG height={25} src={getProductIcon(row.original.state)} />
        </TaskType>
      ),
    }),
    columnHelper.accessor('discontinuationReason', {
      header: 'Discontinuation Reason',
      cell: ({ getValue }) => (getValue() !== null ? getValue() : '-'),
    }),
    columnHelper.accessor('linkedSmpcTitle', {
      header: 'Linked SmPC',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('linkedPilTitle', {
      header: 'Linked PIL',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('hasBlackTriangle', {
      header: () => <BlackTriangle>{'\u25BC'}</BlackTriangle>,
      cell: ({ getValue }) => (getValue() === null ? '-' : !!getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor('legalCategories', {
      header: 'Legal categories',
      cell: ({ getValue }) => <MultiRowText>{(getValue() ?? []).map((x) => getLegalCategories(x)).join('\n')}</MultiRowText>,
    }),
    columnHelper.accessor('maNumbers', {
      header: 'MA Numbers',
      cell: ({ getValue }) =>
        getValue()?.length! > 0 ? (
          <MultiRowText>
            {getValue()
              ?.map((n) => n.number)
              ?.join('\n')}
          </MultiRowText>
        ) : (
          '-'
        ),
    }),
    columnHelper.accessor('atcCodes', {
      header: 'ATC Codes',
      cell: ({ getValue }) => (getValue()?.length! > 0 ? <MultiRowText>{getValue()?.join('\n')}</MultiRowText> : '-'),
    }),
    columnHelper.display({
      header: 'Allergy and Pregnancy risk info',
      cell: ({ row }) => {
        const { isGlutenFree, isLactoseFree, isPregnancyRisk, isSuitableForVegans } = row.original;
        return (
          <AllergyAndPregnancyRiskInfo
            isGlutenFree={isGlutenFree}
            isLactoseFree={isLactoseFree}
            isPregnancyRisk={isPregnancyRisk}
            isSuitableForVegans={isSuitableForVegans}
          />
        );
      },
    }),
    columnHelper.accessor('productId', {
      header: 'Product ID',
    }),
  ];
};

const getContentChangeDisplayValue = (
  taskType: SubmissionTaskType | null,
  documentState: DocumentState,
  documentType: DocumentType,
) => {
  var documentTypeName = documentTypeToCustomLabelMap[documentType];
  var taskTypeName = taskType ? TaskTypeToStringMap[taskType] : documentTypeName;
  return taskType === SubmissionTaskType.DocumentRetire || documentState === DocumentState.Retired
    ? `Retired - ${documentTypeName}`
    : taskTypeName;
};
