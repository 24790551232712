import { useForm } from 'react-hook-form';
import { FormFields, ISubmissionForm } from '../../types';
import { DocumentType, IDocumentDto } from '@common/features/submission/types';
import { validateDocumentTitleUniqueness } from '@common/features/document/helpers';
import { alreadyExistingDocumentTitleMessage } from '@common/components/DocumentTitle/constants';
import { AddModalOpenModes } from '../../AddDocumentModal';

interface IUsePrepareFileUploadFormProps {
  documents?: IDocumentDto[],
  contentType: DocumentType,
  isAddDocumentModalOpen: AddModalOpenModes,
}

export const usePrepareFileUploadForm = ({documents, contentType, isAddDocumentModalOpen}: IUsePrepareFileUploadFormProps) => {
  const methods = useForm<ISubmissionForm>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.documentTitle]: '',
      [FormFields.file]: undefined,
      [FormFields.productIds]: [],
      [FormFields.hasDigitalVersion]: undefined,
      [FormFields.isCustomerApprovalRequired]: null,
    },
  });

  const { register, getValues } = methods;

  register(FormFields.documentTitle, {
    validate: () => {
      const documentTitle = getValues(FormFields.documentTitle);
      const isDocumentTitleUnique = validateDocumentTitleUniqueness(documents!, documentTitle!, contentType!, null);
      
      return isDocumentTitleUnique && isAddDocumentModalOpen === 'Add' ? alreadyExistingDocumentTitleMessage(contentType!) : undefined},
  });

  return { methods, ...methods };
};
