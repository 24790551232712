import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, ButtonTypes } from '@common/components';
import DocumentStatus from '@common/components/DocumentStatus/DocumentStatus';
import DocumentActions from '@common/components/DocumentActions/DocumentActions';
import { IActionCallbacks, IDocumentTableCallbacks } from '@common/components/DatapharmTable/types';
import { IDocumentDto } from '@common/features/submission/types';
import { boolToYesNo, getDaysSinceWithDistanceFormat, formatDate, formatDateTime} from '@common/helpers';
import { IDocumentAttributes, getMediaContentType } from '@common/features/document/helpers';
import { IDropDownButtonOption } from './DropDownButton';
import { DocumentType } from '@common/types';

export const prepareColumns = (
  editable = true,
  callbacks?: IDocumentTableCallbacks,
  documentAttributes?: IDocumentAttributes,
) => {
  const columnHelper = createColumnHelper<IDocumentDto>();

  const base = [
    columnHelper.accessor('documentTitle', {
      header: 'Document title',
    }),
    documentAttributes?.showContentTypeColumn
      ? columnHelper.accessor('type', {
          header: 'Content type',
          cell: ({ getValue }) => getMediaContentType(getValue()),
        })
      : null,
    columnHelper.accessor('version', {
      header: 'Version',
      cell: ({ getValue }) => getValue() ?? '-',
    }),
    columnHelper.accessor('submittedDate', {
      header: 'Submitted date',
      cell: ({ getValue }) => {
        const value = !!getValue() ? new Date(getValue()!) : null;
        return value ? formatDateTime(value) : '-';
      },
    }),
    documentAttributes?.showHasDigitalVersion
      ? columnHelper.accessor('hasDigitalVersion', {
          header: 'ePIL',
          cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        })
      : null,
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Regulator approval date',
          cell: ({ getValue }) => {
            const value = !!getValue() ? new Date(getValue()!) : null;

            return value ? formatDate(value) : '-';
          },
        })
      : null,
    documentAttributes?.showRegulatorApprovalDate
      ? columnHelper.accessor('regulatorApprovalDate', {
          header: 'Days since approval',
          id: 'daysSinceApproval',
          cell: ({ getValue }) => {
            const approvalDate = getValue();
            return getDaysSinceWithDistanceFormat(approvalDate);
          },
        })
      : null,
    columnHelper.accessor('lastUpdateDate', {
      header: 'Date last updated',
      cell: ({ getValue }) => {
        const value = !!getValue() ? new Date(getValue()!) : null;

        return value ? formatDateTime(value) : '-';
      },
    }),
    documentAttributes?.showHcpOnly
      ? columnHelper.accessor('isVisibleToHCP', {
          header: 'HCP',
          cell: ({ getValue }) => boolToYesNo(getValue() ?? false),
        })
      : null,
    columnHelper.accessor('linkedProductIds', {
      header: 'Associated products',
      cell: ({ getValue }) => getValue().length,
    }),
    columnHelper.accessor('documentId', {
      header: 'Content ID',
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (info) => <DocumentStatus value={info.getValue()} />,
    }),
    columnHelper.accessor((row) => row.documentId, {
      header: 'Details',
      cell: ({ row }) => {
        const documentId = row.original.documentId;
        const onClick = () => callbacks?.onDetailsClick!(documentId.toString());

        return <Button type={ButtonTypes.TEXT} text="" height={25} icon="/icons/eye.svg" onClick={onClick} />;
      },
    }),
  ].filter((x) => x);

  return editable
    ? [
        ...base,
        columnHelper.accessor('status', {
          id: 'actions',
          header: 'Actions',
          cell: ({ row, getValue }) => {
            const documentId = row.original.documentId;
            const documentTitle = row.original.documentTitle;
            const state = row.original.state;
            const type = row.original.type;
            const hasDigitalVersion = row.original.hasDigitalVersion;

            const statusCallbacks: IActionCallbacks = {
              onUpdateClick: () => callbacks?.onUpdateClick!(row.original),
              onRemoveClick: () => callbacks?.onRemoveClick!(documentId.toString(), documentTitle),
              onRetireClick: () => callbacks?.onRetireClick!(documentId.toString()),
              onRequestEpilClick: () => callbacks?.onRequestEpilClick!(documentId.toString()),
            };
            return (
              <DocumentActions
                callbacks={statusCallbacks}
                status={getValue()}
                state={state}
                type={type}
                hasDigitalVersion={hasDigitalVersion}
                documentId={documentId}
              />
            );
          },
        }),
      ]
    : base;
};

export const prepareDropdownButtons = (onItemClick: (dt: DocumentType) => void): IDropDownButtonOption[] => [
  {
    buttonText: 'Audio',
    action: () => onItemClick(DocumentType.Audio),
    icon: '/icons/wave.svg',
  },
  {
    buttonText: 'Video',
    action: () => onItemClick(DocumentType.Video),
    icon: '/icons/play.svg',
  },
];
