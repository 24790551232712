import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import ProductStatus from '@components/ProductStatus/ProductStatus';
import ProductActions from '@components/ProductActions/ProductActions';
import { Button, ButtonTypes, getSubstanceNames } from '@common/components';
import { formatDateTime } from '@common/helpers';
import { ErrorLog } from '@common/types';
import { IActionCallbacks, IProductTableCallbacks } from '@common/components/DatapharmTable/types';
import { IProductDto, ISubmissionDto } from '@common/features/submission/types';
import { IValidationModalErrors } from '@common/features/submission/ValidateSubmissionErrors/ValidateSubmissionErrors';

export const prepareProductsColumns = (editable = true, callbacks?: IProductTableCallbacks) => {
  const columnHelper = createColumnHelper<IProductDto>();

  const base = [
    columnHelper.accessor('name', {
      header: 'Product name',
    }),
    columnHelper.accessor('linkedSmpcTitle', {
      header: 'Linked SmPC',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('linkedPilTitle', {
      header: 'Linked PIL',
      cell: ({ getValue }) => (getValue() !== '' ? getValue() : '-'),
    }),
    columnHelper.accessor('lastChangedDate', {
      header: 'Date last updated',
      cell: ({ getValue }) => {
        const value = getValue();

        return value ? formatDateTime(value) : '-';
      },
    }),
    columnHelper.accessor('productId', {
      header: 'Product ID',
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Status',
      cell: (info) => <ProductStatus value={info.getValue()} />,
    }),
    columnHelper.display({
      header: 'Details',
      cell: ({ row }) => {
        return (
          <Button
            type={ButtonTypes.TEXT}
            text=""
            icon="/icons/eye.svg"
            height={25}
            onClick={() => (callbacks?.onDetailsClick ? callbacks?.onDetailsClick!(row.original.productId.toString()) : () => {})}
          />
        );
      },
    }),
  ];

  return editable
    ? [
        ...base,
        columnHelper.accessor('status', {
          id: 'actions',
          header: 'Actions',
          cell: ({ row, getValue }) => {
            const productId = row.original.productId;
            const productName = row.original.name;

            const statusCallbacks: IActionCallbacks = {
              onRemoveClick: () => callbacks?.onRemoveClick!(productId.toString(), productName),
              onUpdateClick: () => callbacks?.onUpdateClick!(productId.toString()),
              onDiscontinueClick: () => callbacks?.onDiscontinueClick!(productId.toString()),
              onReinstatementClick: () => callbacks?.onReinstatementClick!(productId.toString()),
            };

            return <ProductActions status={getValue()} callbacks={statusCallbacks} />;
          },
        }),
      ]
    : base;
};

export const mapProductGroup = (submission?: ISubmissionDto) => {
  const activeIngredients = submission?.productGroup.substances ?? [];
  const substances = getSubstanceNames(activeIngredients);

  return {
    productGroup: submission?.productGroup.productGroupName + substances,
    maHolder: !!submission?.productGroup.maHolderCompanyId,
    companyName: submission?.productGroup.maHolderCompanyName ?? '',
    productType: submission?.productGroup.productType ?? '',
  };
};

export const mapValidationErrors = (errors: ErrorLog[]): IValidationModalErrors => ({
  productGroupErrors: errors.filter((e) => e.mainError === 'Product group information'),
  productsErrors: errors.filter((e) => e.mainError === 'Product Information'),
  contentsErrors: errors.filter((e) => e.mainError === 'Content Information'),
  productAssociationErrors: errors.filter((e) => e.mainError === 'Product does not have an associated SmPC or PIL'),
  contentAssociationErrors: errors.filter((e) => e.mainError === 'Content is not associated to a Product'),
  productUniquenessErrors: errors.filter((e) => e.mainError === 'Product is not unique'),
});
