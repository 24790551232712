import { useCallback, useMemo, useState } from 'react';
import { useUploadFile } from '@hooks/useUploadFile';
import { useDeleteFile } from '@hooks/useDeleteFile';
import { FormFields } from '../../types';
import { useAddDocument } from '@common/features/document/components/DocumentContentDetails/hooks/useAddDocument';
import { abortUpload } from '@api/uploadFileWithProgression';
import { DocumentType, ICheckboxEntry, Id } from '@common/types';
import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';
import { getIsFileValid, getCanUpload, getDocumentAttributes } from '@common/features/document/helpers';
import { useChangeDocumentAssociation } from '@common/features/submissionTask/hooks/useChangeDocumentAssociation';
import { IDocumentDto, IProductDto } from '@common/features/submission/types';
import { AddModalOpenModes } from '../../AddDocumentModal';
import { usePrepareFileUploadForm } from '../usePrepareForm/usePrepareFileUploadForm';

interface IProps {
  contentType: DocumentType;
  submissionId?: string;
  productGroupId: number | null;
  products: IProductDto[] | undefined;
  existingDocuments?: IDocumentDto[];
}

export const useAddDocumentModal = ({
  contentType,
  submissionId,
  products,
  existingDocuments,
}: IProps) => {
  const { goToDocumentContentDetails } = useAppNavigation();
  const { deleteFile } = useDeleteFile();

  const [isModalOpen, setIsModalOpen] = useState<AddModalOpenModes>(null);
  const { methods: fileUploadFormMethods } = usePrepareFileUploadForm({documents: existingDocuments, contentType, isAddDocumentModalOpen: isModalOpen});

  const [showMissingProducts, setShowMissingProducts] = useState<boolean>(false);
  
  const { setValue, reset, getValues, handleSubmit, watch } = fileUploadFormMethods;

  var currentDocumentId = watch(FormFields.documentId);

  const { changeDocumentAssociation, isChangingDocumentAssociation } = useChangeDocumentAssociation(
    parseInt(submissionId as string),
  );

  const documentAttributes = getDocumentAttributes(contentType, false);

  const updateAssociations = (documentId: Id) => {
    var products = getValues(FormFields.productIds);

    if (!products || products.length === 0) {
      goToDocumentContentDetails(submissionId!, contentType, documentId.toString());
      return;
    }

    changeDocumentAssociation(
      { productIds: products.map((o: { id: any }) => o.id), documentId },
      {
        onSuccess: () => {
          goToDocumentContentDetails(submissionId!, contentType, documentId.toString());
        },
      },
    );
  };

  const handleRemove = () => {
    setValue(FormFields.file, undefined);
    clearFileName();
    abortUpload();
    if (!isLoading) {
      deleteFile(fileName);
    }
  };

  const generateProductsCheckboxOptions = (products: IProductDto[]): ICheckboxEntry[] => {
    if (!products) return [];
    return products.map((product) => ({ id: product.productId, value: product.productId, text: product.name }));
  };

  const prepareMatchingProducts = useCallback(
    (prod: IProductDto[]) => {
      const products = prod.filter((o) => o.linkedDocumentIds.some((x) => x === currentDocumentId));
      return generateProductsCheckboxOptions(products || []);
    },
    [currentDocumentId],
  );

  const productsMatchingDocument = useMemo(() => {
    if (!products) return [];

    return prepareMatchingProducts(products);
  }, [prepareMatchingProducts, products]);

  const { uploadFile, isLoading, uploadProgress, fileName, fileErrors, setFileErrors, clearFileName } =
    useUploadFile(handleRemove);

  const { addDocument } = useAddDocument(
    parseInt(submissionId as string),
    contentType,
    fileName,
    getValues(FormFields.file)?.name,
    getValues(FormFields.webLink),
    getValues(FormFields.isCustomerApprovalRequired)!,
    getValues(FormFields.content),
  );

  const handleFileSubmit = (files: FileList | null) => {
    const file = files?.item(0);
    if (file) {
      const errors = getIsFileValid(contentType, file);
      if (errors.length > 0) setFileErrors(errors);
      else {
        setValue(FormFields.file, file);
        uploadFile({ file, documentType: contentType });
      }
    }
  };

  const uploadErrors = () => {
    const errors = getCanUpload(
      contentType,
      getValues(FormFields.documentTitle),
      documentAttributes,
      getValues(FormFields.file),
      getValues(FormFields.webLink),
      getValues(FormFields.hasDigitalVersion),
      getValues(FormFields.isCustomerApprovalRequired)!,
      getValues(FormFields.content),
    );

    if (errors.length > 0) setFileErrors(errors);

    return errors;
  };

  const checkForProduct = (): boolean => {
    const hasMissingProducts = productsMatchingDocument.length > 1 && getValues(FormFields.productIds).length === 0;
    setShowMissingProducts(hasMissingProducts);
    return hasMissingProducts;
  };

  const handleUploadClick = () => {
    const errors = uploadErrors();

    if (errors.length === 0 && !checkForProduct()) {
      handleSubmit(() =>
        addDocument(
          {
            documentId: getValues(FormFields.documentId),
            title: getValues(FormFields.documentTitle),
            hasDigitalVersion: getValues(FormFields.hasDigitalVersion),
          },
          {
            onSuccess: (data) => {
              updateAssociations(data.documentId);
            },
          },
        ),
      )();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(null);
    setShowMissingProducts(false);
    clearFileName();
    clearErrors();
    reset({});
    abortUpload();
  };

  const clearErrors = () => {
    setShowMissingProducts(false);
    setFileErrors([]);
  };

  const handleOpenModal = (
    productGroupName: string,
    documentId?: number,
    linkUrl = '',
    hasDigitalVersion?: boolean,
    mode: AddModalOpenModes = 'Add',
  ) => {
    let documentTitle = productGroupName;

    if (mode === 'Update') {
      const selectedDocument = existingDocuments?.find((d) => d.documentId === Number(documentId));
      documentTitle = selectedDocument?.documentTitle ?? productGroupName;
    }

    reset((fieldValues: any) => ({
      ...fieldValues,
      [FormFields.documentId]: documentId,
      [FormFields.documentTitle]: documentTitle,
      [FormFields.webLink]: linkUrl,
      [FormFields.hasDigitalVersion]: hasDigitalVersion ?? undefined,
      [FormFields.content]: '',
    }));
    setIsModalOpen(mode);
    setShowMissingProducts(false);
  };

  return {
    handleFileSubmit,
    handleUploadClick,
    handleCancel,
    handleRemove,
    handleOpenModal,
    clearErrors,
    uploadErrors,
    showMissingProducts,
    fileErrors,
    isModalOpen,
    isLoading,
    uploadProgress,
    productsMatchingDocument,
    isChangingDocumentAssociation,
    fileUploadFormMethods
  };
};
